import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Testimonial from "../components/testimonial";
import { NewsCarousel } from "../components/newsCarousel";
import ContactPerson from "../components/contactPerson";
import { randomGenerator } from "../utils";
import { PartnersGrid } from "../components/partnersGrid";
import { ServicesTeaser } from "../components/servicesTeaser";

import tk from "../assets/images/logos/svg/tk.svg";
import generali from "../assets/images/logos/svg/generali.svg";
import lebensbaum from "../assets/images/logos/svg/lebensbaum.svg";
import swp from "../assets/images/logos/svg/swp.svg";
import haspa from "../assets/images/logos/svg/haspa.svg";
import hansemerkur from "../assets/images/logos/svg/hansemerkur.svg";
import eppendorf_green from "../assets/images/logos/svg/eppendorf_green.svg";
import cosmos_green from "../assets/images/logos/svg/cosmos_green.svg";
import dak_gesundheit_green from "../assets/images/logos/svg/dak-gesundheit_green.svg";

const Index = () => {
  const images = useStaticQuery(graphql`
    {
      heroes: allFile(
        sort: { fields: relativePath }
        filter: { relativePath: { glob: "home/hero-home-*" } }
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData(quality: 80, layout: FULL_WIDTH)
          }
        }
      }
      dirk: file(relativePath: { eq: "profiles/dirk-schroedter.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const Testimonials = [
    {
      image: dak_gesundheit_green,
      name: "Erik Ober",
      company: "DAK-Gesundheit",
      position: "Product Owner DAK.de",
      text: "Eine ansprechende, informative und leicht zu bedienende Webseite ist mehr als eine Visitenkarte. Sie entwickelt sich immer mehr zu einem Entscheidungskriterium für eine Krankenkasse. Mit der aktuellen Entwicklung einer starken, kundenorientierten Webseite machen wir den nächsten großen Schritt in Richtung Digitaler Vorreiter.",
      linkTo: "dak-gesundheit",
    },
    {
      image: cosmos_green,
      name: "Andreas Scheffler",
      company: "CosmosDirekt",
      position: "Projektleiter Marken-Rebranding CosmosDirekt",
      text: "Ein tolles Ergebnis, so macht Projekt Spaß: Nach langen und intensiven Monaten konnten wir unseren Kunden eine neue, modernere und digitalere Webseite anbieten. Die Zusammenarbeit mit den Kollegen von Monday hat stets Freude bereitet!",
      linkTo: "cosmosdirekt",
    },
    {
      image: eppendorf_green,
      name: "Christiane Klebig",
      company: "Eppendorf Gruppe",
      position:
        "Senior Manager Digital Communications bei der Eppendorf Gruppe",
      text: "Mit der Unterstützung von Monday Consulting konnten wir in kürzester Zeit ein dynamisches Content-Management-System auf Basis von FirstSpirit realisieren, mit dem unsere Mitarbeiter in 33 Ländern einen komfortablen Zugriff auf das gesamte Wissen der Eppendorf Gruppe erhalten.",
      linkTo: "eppendorf",
    },
  ];

  return (
    <Layout lang="de">
      <Seo
        title="Monday Consulting – Digitale Projekte für alle Anforderungen"
        description="Wir bieten erstklassige Expertise für Frontend- und Backend-Entwicklung, CMS-Implementierung, Konzeption und Design mit Fokus auf barrierefreie und personalisierte Erlebnisse."
        keywords="Monday Consulting, CMS, CoreMedia, Crownpeak, FirstSpirit, Frontend, Backend, Consulting, Support, Konzeption, Entwicklung, Integration, Design, Infrastruktur, Personalisierung, Barrierefreiheit, Netlify, Dynamic Yield, IAAP, Formcentric, Formularmanagement"
      />
      <div className="d-md-block parallax-translate bg-shape-vertigo"></div>
      <div className="hero text-color-lg-white mt-4 mt-lg-0">
        <header className="hero-heading mb-4">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className="text-size-2 text-size-md-1">
                  Digitale Projekte für alle Anforderungen.
                  <br />
                  Heute und morgen
                </h1>
                <p className="text-size-4 text-size-md-3">
                  Wir sind auf die barrierefreie Umsetzung personalisierter
                  Kundenerlebnisse spezialisiert – für Ihren langfristigen
                  Erfolg.
                </p>
                <Link
                  to="/leistungen"
                  className="btn bg-monday-skyblue text-color-white text-color-hover-monday-skyblue text-color-hover-lg-white"
                >
                  Unsere Leistungen
                </Link>
              </div>
            </div>
          </div>
        </header>
        <div className="content-container">
          <div className="bg-gradient bg-monday-green">
            <GatsbyImage
              image={
                images.heroes.nodes[
                  randomGenerator(0, images.heroes.nodes.length - 1)
                ].childImageSharp.gatsbyImageData
              }
              className="hero-image"
              alt="Digitale Projekte für alle Anforderungen. Heute und morgen"
            />
          </div>
        </div>
      </div>

      <NewsCarousel />

      <PartnersGrid
        title="Spezialisierung für Ihren Erfolg"
        teaser={
          "Mit unseren starken Partnerschaften gestalten wir barrierefreie und individuelle Erlebnisse von morgen."
        }
      />

      <ServicesTeaser
        title="Entdecken Sie unsere Möglichkeiten"
        text={
          "Mit unserer Expertise in Strategie und Konzeption gestalten wir gemeinsam hochwertige Lösungen, die heute die Standards von morgen erfüllen.\nVon der Auswahl des idealen Content-Management-Systems über einzigartiges Design und Kreation bis hin zur Optimierung von Frontend, Backend und Infrastruktur – wir bieten Ihnen alles aus einer Hand.\n\nWir begleiten Sie durch den gesamten Prozess und stehen auch nach Projektabschluss mit erstklassigem Customer Care zur Seite."
        }
        ctaLink="/leistungen/leistungsportfolio"
        ctaText="Unsere Leistungen im Überblick"
      />

      <div
        className="content-container vertical-spacing bg-monday-green bg-pattern-vertigo text-color-white text-center parallax-background"
        data-spacing-bottom="testimonials"
      >
        <div className="container pt-4 pt-md-5 pb-3">
          <div className="row mb-3">
            <div className="col-12 d-flex flex-column align-items-center">
              <h2 className="mb-3">
                Diese Unternehmen setzen auf Monday Consulting
              </h2>
              <p>
                Unternehmen aus diversen Branchen verbessern mit unseren
                individuellen und zukunftssicheren Lösungen den Dialog mit den
                Kunden sowie interne Prozesse und sichern so ihren Erfolg am
                Markt. Dabei gleicht kein Case dem anderen und das macht uns
                besonders stolz.
              </p>
              <Link className="btn" to="/referenzen">
                Unsere Referenzen
              </Link>
            </div>
          </div>
          <div className="row mt-4 mb-2 align-items-center justify-content-center">
            <div className="p-2 flex-grow-0 mb-2">
              <img src={generali} alt="Generali" height="50px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={lebensbaum} alt="Lebensbaum" height="75px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={tk} alt="Techniker Krankenkasse" height="50px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={hansemerkur} alt="HanseMerkur" height="50px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={swp} alt="Stadtwerke Potsdam" height="50px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={haspa} alt="Hamburger Sparkasse" height="50px" />
            </div>
          </div>
        </div>
      </div>

      <div
        id="testimonials"
        className="container vertical-offset mb-5 mb-md-6 px-0 px-md-3"
        data-offset="50%"
      >
        <div className="swiper-container reveal">
          <div className="swiper-wrapper">
            {Testimonials.map((item, i) => (
              <Testimonial key={i} {...item} />
            ))}
          </div>
          <div className="swiper-pagination d-xl-none"></div>
        </div>
      </div>

      <ContactPerson
        headline="Interesse an unseren Leistungen geweckt?"
        text="Kontaktieren Sie uns noch heute, damit wir gemeinsam Ihren digitalen Vorsprung ausbauen können."
        contactEmail="business@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Dirk Schrödter, Geschäftsführer"
        withGetInTouch={false}
      >
        <GatsbyImage
          image={images.dirk.childImageSharp.gatsbyImageData}
          alt="Dirk Schrödter"
        />
      </ContactPerson>
    </Layout>
  );
};

export default Index;
